// Here you can add other styles

body {
  background-color: #557a95;
  background-image: url('../../src/assets/images/fondo2.png');
  height: auto;
  // font-family: 'Calibri', sans-serif !important;
  // color: rgba(0, 0, 0, 60);
}

.project-accordion {
  --cui-accordion-btn-color: white;
  --cui-accordion-btn-bg: #3c763d;
  --cui-accordion-active-color: white;
  --cui-accordion-active-bg: #3c763d;
}

.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.carousel {
  width: 85%;
  height: auto;
}
.nav-accordion {
  z-index: 1000;
  .accordion-button {
    // padding-bottom: 0px !important;
    .nav-link {
      color: white;
    }
    .active {
      color: black;
    }
  }
}

.informative-panel-accordion {
  .btn {
    background-color: #00778b;
    color: white;
    margin-left: 40%;
  }
}

.skynav {
  .btn {
    background-color: #00778b;
    color: white;
    margin-left: 40%;
  }
}

.technicalDocumentation {
  .btn {
    background-color: #00778b;
    color: white;
    margin-bottom: 10px;
  }

  .actions-card {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.turnOverPackage {
  .btn {
    background-color: #00778b;
    color: white;
    margin-bottom: 10px;
  }

  .actions-card {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.proyect-administration {
  .action-buttons {
    margin-bottom: 10px;
    .btn {
      background-color: #00778b;
      color: white;
      margin-left: 10px;
    }
  }
}

.contract-administration {
  .action-buttons {
    margin-bottom: 10px;
    .btn {
      background-color: #00778b;
      color: white;
      margin-left: 10px;
    }
  }
}

.project-creation-modal {
  .row {
    margin-bottom: 2%;
  }
  .btn-add {
    background-color: #00778b;
    color: white;
  }
}

.category-creation-modal {
  .row {
    margin-bottom: 2%;
  }
  .btn-add {
    background-color: #00778b;
    color: white;
  }
  .btn-edit {
    background-color: #00778b;
    color: white;
    margin-right: 5px;
  }
  .btn-del {
    background-color: #00778b;
    color: white;
  }
}

.project-list {
  --cui-accordion-btn-color: black;
  --cui-accordion-btn-bg: white;
  --cui-accordion-active-color: black;
  --cui-accordion-active-bg: white;
  --cui-accordion-btn-focus-border-color: white;
  --cui-accordion-btn-focus-box-shadow: white;

  .accordion-body {
    background-color: #dee2e6;
  }

  .btn-project-action {
    background-color: #00778b;
    margin: 5px;
    color: white;
  }
}

.contract-list {
  --cui-accordion-btn-color: black;
  --cui-accordion-btn-bg: white;
  --cui-accordion-active-color: black;
  --cui-accordion-active-bg: white;
  --cui-accordion-btn-focus-border-color: white;
  --cui-accordion-btn-focus-box-shadow: white;

  .accordion-body {
    background-color: #dee2e6;
  }

  .btn-project-action {
    background-color: #00778b;
    margin: 5px;
    color: white;
  }
}

.login-container {
  background-color: #557a95;
  background-image: url('../../src/assets/images/fondo.png');

  .login-title {
    font-size: 1.75rem;
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: black;
  }

  .text-body-secondary {
    text-align: center;
  }

  .login-button {
    background-color: #b0a295;
    border-color: #b0a295;
    color: white;
    width: 100%;
  }
}

.project-selector-container {
  .project-title-back {
    color: #fff;
    // font-size: 1.5em;
    // font-weight: 300;
    text-align: justify;
  }
  .project-title {
    color: #fff;
    font-size: 1.5em;
    font-weight: 300;
    text-align: justify;
  }

  .project-manager {
    color: #fff;
    font-size: 1em;
    font-weight: 300;
    text-align: center;
  }
}

.project-description {
  .project-img-container {
    text-align: center;
  }
}

.dailyReport {
  input {
    border-style: solid;
    border-color: gray;
  }

  textarea {
    border-style: solid;
    border-color: black;
  }
  .dailyReport-accordion {
    --cui-accordion-btn-color: white;
    --cui-accordion-btn-bg: #00778b;
    --cui-accordion-active-color: white;
    --cui-accordion-active-bg: #00778b;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .custom-accordion-header {
    position: relative;
    overflow: hidden; /* Para evitar que los colores sobresalgan */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Asegura que el contenido y los colores se alineen bien */

    .corner-colors {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      width: 100px; /* Ajusta el ancho de los colores */
      height: 10px;
    }

    .color-box {
      flex: 1;
      height: 100%;
    }

    .yellow {
      background-color: #eaaa00;
    }
    .red {
      background-color: #d8282f;
    }
    .light-blue {
      background-color: #64ccc9;
    }
    .dark-blue {
      background-color: #00778b;
    }
    .gray {
      background-color: #898b8e;
    }
  }

  .table {
    --cui-table-bg: none;
  }

  .company-report {
    .row {
      margin-bottom: 2%;
    }
  }
}

.dashboard {
  // background-color: orange;
  width: 100%;
  height: 100%;

  .row {
    padding: 10px;
    .dashboard-button {
      width: 100%;
      background: #00778b;

      height: 200px;
      color: white;
      font-weight: 600;
      font-size: 1.5rem;
      transition:
        transform 0.2s,
        box-shadow 0.3s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden; /* Evita que los colores sobresalgan */

      .corner-colors {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 80px; /* Ajusta según el tamaño deseado */
        height: 15px;
      }

      .color-box {
        flex: 1;
        height: 100%;
      }

      .yellow {
        background-color: #eaaa00;
      }
      .red {
        background-color: #d8282f;
      }
      .light-blue {
        background-color: #64ccc9;
      }
      .dark-blue {
        background-color: #00778b;
      }
      .gray {
        background-color: #898b8e;
      }

      .button-container {
        display: flex;
        flex-direction: row;
        .button-label {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10%;
        }

        .icon-button {
          opacity: 0.5;
          height: 3em;
        }
      }
    }
  }

  .col {
    padding: 10px;
    .dashboard-button {
      background: linear-gradient(135deg, #019999, #1fc8db);
      width: 200px;
      height: 200px;
      color: white;
      font-weight: 600;
      font-size: 1.5rem;
      transition:
        transform 0.2s,
        box-shadow 0.3s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  @media only screen and (max-width: 800px) {
    .row {
      .dashboard-button {
        width: 100%;
        height: 200px;
        margin-bottom: 5px;
      }
    }
  }

  @media only screen and (max-width: 1300px) {
    .row {
      .dashboard-button {
        .button-container {
          .icon-button {
            height: 2em;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .row {
      .dashboard-button {
        .button-container {
          .icon-button {
            height: 1em;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .row {
      .dashboard-button {
        .button-container {
          .icon-button {
            height: 3em;
          }
        }
      }
    }
  }
}

.daily-report {
  .btn {
    background-color: #00778b;
    color: white;
  }
}

.resume-table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

// toast fade bg-danger border-0 show text-white align-items-center
.toast {
  width: 100%;
}

.form-group {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.project-selector-container {
  margin: 0 auto;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;

  h3 {
    margin-top: 5%;
  }
}

.contract-selector-container {
  margin: 0 auto;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;

  h3 {
    margin-top: 5%;
  }
}

.loading {
  width: 100%;
  height: 100%;
}

// .sidebar-style {
//   background-image: url('../assets/images/menu.png');
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   height: 100%;
// }

.sidebar-style {
  position: relative;
  overflow: hidden; /* Evita que ::before sobresalga */
}

.sidebar-style::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/menu.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.75; /* Opacidad al 50% */
  z-index: -1; /* Detrás del contenido */
}

.sidebar-nav .nav-group-toggle:after {
  background-image: url('../assets/images/icons/arrow.svg');
}

.nav-link {
  cursor: pointer;
}

.sidebar-footer {
  cursor: pointer;
}

.cancel-btn {
  background: #d0262f;
  color: white;
}
.confirm-btn {
  background: #02798b;
  color: white;
}

.node {
  --cui-table-bg: #64ccc9 !important;

  td {
    font-weight: bold !important;
  }
}

h2 {
  color: #00778b;
}
